import React from "react"
import { makeStyles, Button, Typography, Link } from "@material-ui/core"

import Logo from "./assets/footer_logo.svg"
import Facebook from "./assets/facebook.svg"
import Instagram from "./assets/instagram.svg"
import Linkedin from "./assets/linkedin.svg"
import Twitter from "./assets/twitter.svg"

const socials = [
  {
    logo: Facebook,
    url: "https://www.facebook.com/Murfy.fr/",
  },
  {
    logo: Instagram,
    url: "https://www.instagram.com/murfy.fr/",
  },
  {
    logo: Linkedin,
    url: "https://www.linkedin.com/company/murfy-repair/",
  },
  {
    logo: Twitter,
    url: "https://twitter.com/MurfyFR",
  },
]

function CustomLink({ link, className, type, subClassName }) {
  const Tag = type || "span"
  return (
    <Link
      href={link.url}
      target={link.openLiknInNewTab ? "_blank" : "_self"}
      rel="noopener noreferrer"
      className={className}
    >
      <Tag className={subClassName}> {link.title}</Tag>
    </Link>
  )
}

export default function Footer({
  blog,
  cgv,
  commitmentsLinks,
  commitmentsTitle,
  legals,
  offerLinks,
  offerTitle,
}) {
  const classes = useStyle()
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <a href="https://murfy.fr/">
            <Logo />
          </a>
          <div className={classes.socials}>
            {socials.map(({ logo, url }) => {
              return (
                <Link
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={url}
                  className={classes.socialsLink}
                >
                  {logo()}
                </Link>
              )
            })}
          </div>
        </div>

        <div className={classes.subcontent}>
          <div className={classes.lists}>
            <div className={classes.list}>
              <Typography variant="body2" className={classes.title}>
                {offerTitle}
              </Typography>
              <ul>
                {offerLinks.map(({ offerLink }) => (
                  <CustomLink
                    key={offerLink.title}
                    link={offerLink}
                    type="li"
                    subClassName={classes.item}
                  />
                ))}
              </ul>
            </div>
            <div className={classes.list}>
              <Typography variant="body2" className={classes.title}>
                {commitmentsTitle}
              </Typography>
              <ul>
                {commitmentsLinks.map(({ commitmentsLink }) => (
                  <CustomLink
                    key={commitmentsLink.title}
                    link={commitmentsLink}
                    type="li"
                    subClassName={classes.item}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className={classes.others}>
            <CustomLink link={blog} />
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            target="_blank"
            rel="noopener"
            href="https://murfy.zendesk.com/hc/fr/"
          >
            Besoin d'aide ?
          </Button>
        </div>
      </div>
      <div className={classes.legals__container}>
        <div className={classes.credits}>
          <Typography variant="caption">
            © {new Date().getFullYear()} - Murfy
          </Typography>
        </div>
        <div className={classes.legals}>
          <CustomLink link={cgv} />
          <CustomLink link={legals} />
        </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  container: {
    overflowX: "hidden",
    backgroundColor: theme.palette.secondary.dark,
    width: "100%",
    color: "white",
    padding: "128px 10% 20px 10%",

    [theme.breakpoints.down("sm")]: {
      padding: "64px 5% 20px 5%",
    },

    "& a": {
      textDecoration: "none",
      color: "inherit",
    },

    "& ul": {
      padding: "0",
      margin: "0",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  subcontent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "60%",
    height: "max-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  socialsLink: {
    "& > svg": {
      minWidth: 19,
      maxWidth: 19,
      minHeight: 19,
      maxHeight: 19,
    },
  },
  logo: {
    padding: "0 10px",
    "& svg": {
      transition: "400ms",
      "&:hover": {
        cursor: "pointer",
        transform: "rotate(360deg)",
        transition: "1000ms",
      },
    },
  },
  lists: {
    display: "flex",
    justifyContent: "flex-start",
    "& > :first-child": {
      marginRight: 130,
    },
    [theme.breakpoints.down("xs")]: {
      "& > :first-child": {
        paddingLeft: 0,
        marginRight: 30,
      },
    },
  },
  list: {
    padding: "0 10px",
  },
  title: {
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "140%",
    margin: "0 0 24px 0",
  },
  item: {
    listStyleType: "none",
    color: "white",
    textDecoration: "none",
    marginBottom: "8px",
  },
  button: {
    whiteSpace: "nowrap",
    "& > span": {
      color: theme.palette.secondary.main,
    },
  },
  socials: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "100px",

    [theme.breakpoints.down("sm")]: {
      width: "200px",
      margin: "50px 0",
    },
  },
  others: {
    display: "flex",
    width: "20%",
    padding: "0 10px",
    marginTop: "70px",

    [theme.breakpoints.down("sm")]: {
      margin: "20px 0 50px 0",
    },
  },
  legals__container: {
    marginTop: "80px",
    display: "flex",
    padding: "0 0 0 10px",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "30px",
      alignItems: "flex-start",
    },
    "& span": {
      color: "white",
    },
    "& a": {
      marginLeft: "40px",
      fontSize: "13px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 40px 0 0px",
      },
    },
  },
}))

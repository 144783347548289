import * as React from "react"

const LOCALSTORAGE_KEY_USER = "murfy_reconditionne_user_V3"

export default function useUser() {
  // sync user token between browser tabs
  const [user, setUser] = React.useState({ first_name: "", last_name: "" })
  React.useEffect(() => {
    function syncToken() {
      const data = JSON.parse(
        window.localStorage.getItem(LOCALSTORAGE_KEY_USER)
      )
      if (data) {
        setUser(data.value)
      }
    }
    window.addEventListener("storage", syncToken)
    return () => {
      window.removeEventListener("storage", syncToken)
    }
  }, [])

  // Load user from localStorage
  React.useEffect(() => {
    const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY_USER))
    if (data) {
      setUser(data.value)
    }
  }, [])

  function disconnect() {
    setUser({ first_name: "", last_name: "" })
    window.localStorage.removeItem(LOCALSTORAGE_KEY_USER)
  }
  return [user, disconnect]
}

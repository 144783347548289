import React, { useState } from "react"
import { makeStyles, Typography, Button } from "@material-ui/core"
import { RoundedInput } from "../roundedInput/RoundedInput"

export default function NewsletterCallToActionBloc({
  title,
  text,
  emailPlaceholder,
  buttonText,
  isBackgroundWhite,
}) {
  const [email, setEmail] = useState("")
  const styles = useStyle({ isBackgroundWhite })
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <Typography variant="h3" color="secondary" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={styles.subtext}>
          {text}
        </Typography>
      </div>
      <div className={styles.embeddedSubscribe}>
        <form
          action="https://murfy.us3.list-manage.com/subscribe/post?u=0c538a0e1e9fde3664dc929ee&amp;id=92bbdb71bc"
          method="post"
          id="embeddedSubscribe-form"
          name="embeddedSubscribe-form"
          target="_blank"
          noValidate
        >
          <div className={styles.signupScroll}>
            <RoundedInput
              backgroundColor="white"
              variant="outlined"
              type="email"
              name="EMAIL"
              className={styles.input}
              autoComplete="email"
              id="mceEmail"
              placeholder={emailPlaceholder}
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            <div aria-hidden="true">
              <input
                type="hidden"
                name="b_0c538a0e1e9fde3664dc929ee_92bbdb71bc"
                tabIndex="-1"
                value=""
              />
            </div>
            <Button variant="contained" color="primary">
              {buttonText}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  container: {
    margin: "auto",
    width: "min(1034px , 90%)",
    borderRadius: 9,
    backgroundColor: ({ isBackgroundWhite }) =>
      isBackgroundWhite ? "#F6F6F6" : theme.palette.secondary.main,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(),
    color: ({ isBackgroundWhite }) =>
      isBackgroundWhite
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
  text: {
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
  },
  input: {
    width: "100%",
    marginRight: theme.spacing(),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(),
      marginRight: 0,
    },
  },
  embeddedSubscribe: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  signupScroll: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}))

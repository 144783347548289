import Action from "./assets/action.svg"
import {
  Container,
  Grid,
  Box,
  Typography,
  makeStyles,
  useTheme,
  Divider,
  Button,
} from "@material-ui/core"

import { lighten } from "@material-ui/core/styles"
import * as React from "react"

const useChart = color => {
  const theme = useTheme()
  const chartColor = {
    Green: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.yellow,
      link: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
    Pink: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      link: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
    Yellow: {
      backgroundColor: theme.palette.yellow,
      color: theme.palette.secondary.main,
      link: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.yellow,
      },
    },
  }

  return chartColor[color || "Green"]
}
const Element = ({ isButton, children, ...props }) => {
  if (isButton) {
    return (
      <a {...props} style={{ cursor: "pointer" }}>
        {children}
      </a>
    )
  }
  const { href, ...rest } = props
  return <div {...rest}>{children}</div>
}

function Item({ link, chart, handleHover, index, indexOpen }) {
  const subBlockChart = useChart(link.subItemBlock?.backgroundColor)
  const classes = useStyles({ chart, subBlockChart })
  return (
    <Element
      isButton={Boolean(link.url)}
      key={index}
      href={`/${link.url}`}
      className={classes.item}
      onMouseLeave={handleHover(index)}
      onMouseEnter={handleHover(index)}
    >
      <div style={{ position: "relative" }}>
        {link.title}
        {index === 2 && <Action className={classes.action} />}
      </div>
      {indexOpen === index && link.menuItem?.length > 0 && (
        <div className={classes.subMenu}>
          <Container maxWidth="md">
            <Grid container alignItems="flex-start">
              <Grid item xs={8} container>
                {link.menuItem?.map((item, index) => (
                  <Grid item xs={6} key={index}>
                    <Box m={1}>
                      <a className={classes.link} href={item.link}>
                        {item.icon && (
                          <img
                            src={item.icon}
                            width={32}
                            height={32}
                            alt="icon"
                          />
                        )}
                        <Box ml={3}>
                          <Typography
                            color="secondary"
                            variant="body1"
                            className={classes.title}
                          >
                            <b>{item.title}</b>
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.subtitle}
                          >
                            {item.subTitle}
                          </Typography>
                        </Box>
                      </a>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={4}>
                {link.subItemBlock && (
                  <Box p={2} className={classes.subBlock}>
                    <Typography variant="h1">
                      {link.subItemBlock.title}
                    </Typography>
                    <Typography className={classes.subtitleText}>
                      {link.subItemBlock.subTitle}
                    </Typography>
                    <Box mt={2} mb={2}>
                      <Divider className={classes.divider} />
                    </Box>
                    <Grid container justify="flex-end">
                      {link.subItemBlock.link && (
                        <Grid item>
                          <Button
                            className={classes.button}
                            href={link.subItemBlock.url}
                          >
                            {link.subItemBlock.link}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </Element>
  )
}

const useStyles = makeStyles(theme => ({
  subtitleText: {
    marginTop: 40,
  },
  subMenu: {
    zIndex: 1,
    display: "block",
    position: "absolute",
    boxShadow: "0px 1px #e6e6e6",
    left: 0,
    width: "100%",
    overflow: "hidden",
    padding: theme.spacing(6, 0),
    top: 80,
    backgroundColor: theme.palette.common.white,
    "& > div": {
      animation: `$bounceMenuItem 700ms cubic-bezier(0.25, 0.1, 0.1, 1)`,
    },
  },
  "@keyframes bounceMenuItem": {
    "0%": {
      opacity: 0,
      transform: "translateY(-300%)",
    },
    "60%": {
      opacity: 1,
      transform: "translateY(8%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  subBlock: ({ subBlockChart }) => ({
    padding: theme.spacing(3),
    backgroundColor: subBlockChart.backgroundColor,
    color: subBlockChart.color,
  }),
  divider: ({ subBlockChart }) => ({
    backgroundColor: subBlockChart.color,
  }),
  button: ({ subBlockChart }) => ({
    backgroundColor: subBlockChart.link.backgroundColor,
    color: subBlockChart.link.color,
    "&:hover": {
      backgroundColor: lighten(subBlockChart.link.backgroundColor, 0.2),
    },
  }),
  item: ({ chart }) => ({
    backgroundColor: "transparent",
    border: "none",
    outline: "0 none",
    color: chart.color,
    alignItems: "center",
    height: "100%",
    fontSize: 20,
    lineHeight: "140%",
    fontWeight: 500,
    textDecoration: "none",
    paddingTop: theme.spacing(3),
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
    margin: "0 16px",
    padding: "0 8px",
    "&:hover": {
      color: chart.hover,
    },
    "&:after": {
      transform: "scaleX(0)",
      content: "''",
      display: "block",
      width: "100%",
      bottom: 0,
      transition: "transform 250ms ease-in-out",
      borderBottom: `solid 4px ${chart.hover}`,
    },
    "&:hover:after": {
      transform: "scaleX(1)",
    },
  }),
  action: ({ chart }) => ({
    position: "absolute",
    top: 0,
    color: chart.hover,
    right: -13,
  }),
  link: {
    padding: theme.spacing(2),
    border: "none",
    outline: "0 none",
    wdith: "100%",
    cursor: "pointer",
    borderRadius: 16,
    display: "flex",
    textAlign: "left",
    alignItems: "flex-start",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  },
  title: {
    margin: 0,
    padding: 0,
  },
  subtitle: {
    color: "#787878",
    marginTop: 4,
  },
}))

export default React.memo(Item)

import React from 'react'
import 'lazysizes'

const ImageWithLazyload = (data) => {
    const { src, alt, className, isPreview } = data
    return (
        isPreview
            ? <img src={src} alt={alt} className={ className } />
            : <img data-src={src} alt={alt} className={className + ' lazyload'} />
    )
}

export default ImageWithLazyload
import React from "react"
import {
  Container,
  Typography,
  makeStyles,
  Button,
  Link,
} from "@material-ui/core"
import { repairStorage } from "../../helpers/repairStorage"
import { RoundedInput } from "components/roundedInput/RoundedInput"
import PlaceIcon from "@material-ui/icons/Place"
import { GoogleAutoComplete } from "components/googleAutoComplete/GoogleAutoComplete"

export default function Compo({ title, subtitle, searchTitle }) {
  const classes = useStyles()
  const handleChangeAddress = React.useCallback(address => {
    repairStorage.setAddress(address)
  }, [])
  return (
    <Container maxWidth="md" className={classes.container}>
      <div className={classes.inner}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
        <div className={classes.adressContainer}>
          <GoogleAutoComplete onChange={handleChangeAddress}>
            <RoundedInput
              fullWidth
              backgroundColor="white"
              placeholder="Saisissez votre adresse"
              InputProps={{
                endAdornment: <PlaceIcon style={{ color: "#A4E7DB" }} />,
              }}
            />
          </GoogleAutoComplete>
          <Link href="/rendez-vous-reparateur/choix-de-creneau">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Typography className={classes.buttontext}>
                C'est parti
              </Typography>
            </Button>
          </Link>
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  buttontext: {
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  },
  adressContainer: {
    display: "flex",
    marginTop: theme.spacing(6),
    alignItems: "center",
    "& :first-child": {
      marginRight: theme.spacing(),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 680,
    margin: "auto",
  },
  container: {
    backgroundColor: "#F6F6F6",
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: "auto",
    },
  },
  subtitle: {
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(2),
  },
}))

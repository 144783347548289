import React from "react"
import {
  makeStyles,
  ClickAwayListener,
  ButtonBase,
  Typography,
  Link as MuiLink,
} from "@material-ui/core"
import { animated, config, useSpring } from "react-spring"

import Tick from "./assets/tick.svg"
import Disconnect from "./assets/disconnect.svg"

const TAB_INFOS = {
  href: "/reconditionne/mon-compte",
  label: "Infos personnelles",
  labelMobile: "Infos",
}
const TAB_REPARE = {
  href: "/reconditionne/mon-compte/reparation",
  label: "Réparation",
}
const TAB_ORDER = {
  href: "/reconditionne/mon-compte/commandes",
  label: "Commandes",
}

export default function AccountModal({ onClose, disconnect }) {
  const classes = useStyle()
  const style = useSpring({
    config: config.stiff,
    from: {
      transform: "translateY(-50%) translateX(45%) scale(0)",
      opacity: 0,
    },
    to: { transform: "translateY(0%) translateX(0%) scale(1)", opacity: 1 },
  })

  return (
    <ClickAwayListener onClickAway={onClose}>
      <animated.div style={style} className={classes.container}>
        <Tick className={classes.tick} />
        <MuiLink
          underline="none"
          className={classes.link}
          href={TAB_REPARE.href}
          color="inherit"
          variant="body1"
          align="left"
        >
          Réparations
        </MuiLink>
        <MuiLink
          underline="none"
          href={TAB_INFOS.href}
          className={classes.link}
          color="inherit"
          variant="body1"
          align="left"
        >
          Infos personnelles
        </MuiLink>
        <MuiLink
          href={TAB_ORDER.href}
          underline="none"
          className={classes.link}
          color="inherit"
          variant="body1"
          align="left"
        >
          Commandes
        </MuiLink>
        <div className={classes.divider} />
        <ButtonBase
          disableRipple
          onClick={disconnect}
          className={classes.disconnect}
        >
          <Disconnect className={classes.icon} />
          <Typography noWrap>Se déconnecter</Typography>
        </ButtonBase>
      </animated.div>
    </ClickAwayListener>
  )
}

const useStyle = makeStyles(theme => ({
  container: {
    position: "absolute",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    right: 35,
    top: "150%",
    zIndex: 200,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 4px 76px rgba(0, 0, 0, 0.15)",
  },
  divider: {
    height: 1,
    backgroundColor: "#D7D7D7",
    width: "100%",
  },
  tick: {
    position: "absolute",
    top: -9,
    right: theme.spacing(3),
  },
  link: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    "&:hover": {
      color: theme.palette.grey[600],
    },
  },
  disconnect: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "flex-end",
    fill: theme.palette.text.primary,
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.grey[600],
      fill: theme.palette.grey[600],
    },
  },
  icon: {
    marginRight: theme.spacing(2),
    fill: "inherit",
  },
}))

import * as React from "react"
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  Link,
  ListItemText,
  Container,
  Collapse,
  IconButton,
  useTheme,
  Grid,
  Typography,
  ButtonBase,
  Hidden,
  ListItemSecondaryAction,
  Box,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"

import MobileLogo from "./assets/mobileLogo.svg"
import Arrow from "./assets/arrow.svg"

import DesktopLogo from "./assets/desktopLogo.svg"
import PersonIcon from "./assets/profile.svg"
import MenuIcon from "@material-ui/icons/Menu"
import clsx from "clsx"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import useUser from "../../hooks/useUser"
import MapIcon from "./assets/mapIcon.svg"
import AccountModal from "./AccountModal"
import Item from "./Item"

function formatName(user) {
  if (!user.first_name && !user.last_name) {
    return "Mon compte"
  }
  return `${(user.first_name || " ").slice(0, 1).toUpperCase()}${(
    user.first_name || ""
  ).slice(1)} ${(user.last_name || " ").slice(0, 1).toUpperCase()}.`
}

const useThemeFromColor = color => {
  const theme = useTheme()
  const chartColor = {
    Green: {
      color: theme.palette.common.white,
      hover: theme.palette.primary.main,
    },
    Pink: {
      color: theme.palette.common.white,
      hover: theme.palette.secondary.main,
    },
    Yellow: {
      color: "#1A8E96",
      hover: theme.palette.secondary.main,
    },
    White: {
      color: theme.palette.secondary.main,
      hover: theme.palette.primary.main,
    },
  }

  return chartColor[color || "Green"]
}

export function Navigation({ navigation, type, displayHeaderBanner = false }) {
  const { links, color } = navigation
  const [user, disconnect] = useUser()
  const chart = useThemeFromColor(color)
  const [isSticky, setIsSticky] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [mobileSubMenuOpen, setMobileSubMenuOpen] = React.useState(null)
  const [indexOpen, setIndexOpen] = React.useState(null)
  const classes = useStyles({
    type,
    chart,
    displayHeaderBanner,
    indexOpen,
    color,
  })

  const ref = React.useRef()
  const handleHover = index => () => {
    setIndexOpen(prevState => (prevState === index ? null : index))
  }
  const handleOpenMenu = React.useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [])

  const handleOpen = React.useCallback(
    (index, isDirectLink, link) => () => {
      if (!isDirectLink) {
        setMobileSubMenuOpen(prevState => (prevState === index ? null : index))
      } else {
        window.location.pathname = link
      }
    },
    []
  )
  const scrollListener = React.useCallback(() => {
    if (window.scrollY > ref.current.clientHeight) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }, [ref])
  const [openAccountModal, setOpenAccountModal] = React.useState(false)
  React.useEffect(() => {
    if (ref.current) {
      document.addEventListener("scroll", scrollListener)
    }
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [ref, scrollListener])
  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.menu}>
        <Hidden smUp implementation="css">
          <div className={classes.icon}>
            <IconButton
              size="small"
              aria-label="open-menu"
              onClick={handleOpenMenu}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </div>
        </Hidden>
        <Hidden xsDown implementation="css" className={classes.listItem}>
          <div className={classes.innerListItem}>
            {links.map(({ link }, index) => (
              <Item
                link={link}
                indexOpen={indexOpen}
                chart={chart}
                handleHover={handleHover}
                index={index}
              />
            ))}
          </div>
        </Hidden>
        <a
          className={clsx(
            { [classes.isSticky]: isSticky && type !== "article" },
            classes.logo
          )}
          href="/"
        >
          <Hidden xsDown implementation="css">
            <DesktopLogo className={classes.logoIcon} />
          </Hidden>
          <Hidden smUp implementation="css">
            <MobileLogo className={classes.logoIcon} />
          </Hidden>
        </a>
        <Hidden smUp implementation="css" className={classes.icon}>
          <a
            href={
              user.token
                ? "/reconditionne/mon-compte"
                : "/reconditionne/se-connecter"
            }
            className={classes.iconInner}
          >
            <IconButton
              size="small"
              aria-label="open-menu"
              className={classes.personButton}
            >
              <PersonIcon className={classes.personIcon} />
            </IconButton>
          </a>
        </Hidden>
        <Hidden xsDown implementation="css" className={classes.iconDesktop}>
          {!user.token ? (
            <a
              href="/reconditionne/se-connecter"
              className={classes.iconDesktopInner}
            >
              Se connecter
              <PersonIcon className={classes.profile} />
            </a>
          ) : (
            <div className={classes.accountLinkConnected}>
              <ButtonBase
                disableRipple
                onClick={() => setOpenAccountModal(true)}
              >
                <Typography className={classes.account}>
                  {formatName(user)}
                </Typography>
                <PersonIcon className={classes.profileIcon} />
              </ButtonBase>
              {openAccountModal && (
                <AccountModal
                  disconnect={disconnect}
                  onClose={() => setOpenAccountModal(false)}
                />
              )}
            </div>
          )}
        </Hidden>
      </div>
      <Drawer
        PaperProps={{ className: classes.paperDrawer }}
        open={isOpen}
        origin="left"
      >
        <Box pt={3} pl={3} pb={2}>
          <IconButton onClick={handleOpenMenu} className={classes.iconButton}>
            <ClearIcon className={classes.clearIcon} />
          </IconButton>
        </Box>
        <List component="nav" className={classes.drawerList}>
          {links.map(({ link }, index) => {
            return (
              <div key={index}>
                <ListItem
                  button
                  onClick={handleOpen(index, !Boolean(link.menuItem), link.url)}
                  key={index}
                  className={clsx(
                    {
                      [classes.drawerListItemActive]:
                        mobileSubMenuOpen === index,
                    },
                    classes.drawerListItem
                  )}
                >
                  <ListItemText
                    primary={link.title}
                    className={classes.drawerListItemText}
                  />
                  <Arrow
                    className={clsx({
                      [classes.expandLess]:
                        link.menuItem?.length > 0 &&
                        mobileSubMenuOpen === index,
                      [classes.expandMore]: link.menuItem?.length > 0,
                    })}
                  />
                </ListItem>
                <Collapse in={mobileSubMenuOpen === index}>
                  <Box pt={1} pb={1}>
                    <List component="nav" className={classes.drawerListSmall}>
                      {link.menuItem?.map((menuItem, subIndex) => {
                        return (
                          <ListItem
                            button
                            onClick={() =>
                              (window.location.pathname = menuItem.link)
                            }
                            key={`${index} ${subIndex}`}
                          >
                            <ListItemText
                              primary={menuItem.title}
                              className={classes.drawerListSubItemText}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                size="small"
                                edge="end"
                                aria-label="go to"
                              >
                                <ChevronRightIcon
                                  className={classes.drawerListSubItemIcon}
                                />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Box>
                </Collapse>
              </div>
            )
          })}
        </List>
        <div className={classes.bottomActions}>
          <Link
            href="https://murfy.zendesk.com/hc/fr/"
            underline="none"
            target="_blank"
            rel="noopener"
          >
            <Typography variant="body2" color="secondary">
              Besoin d'aide ?
            </Typography>
          </Link>
          {/* TODO when multilangage is supported */}
          {/* <Typography variant="body2" className={classes.language}>
          <MapIcon className={classes.mapIcon} /> Français
        </Typography> */}
        </div>
      </Drawer>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: ({ type, indexOpen, color }) => ({
    position: type === "article" ? "relative" : "absolute",
    top: 0,
    left: 0,
    zIndex: 100,
    width: "100%",
    backgroundColor:
      (indexOpen === 0 || indexOpen === 2) && color === "Pink"
        ? theme.palette.primary.main
        : "transparent",
  }),
  personIcon: ({ chart }) => ({
    color: chart.hover,
  }),
  accountLinkConnected: {
    color: "white",
    fill: "white",
  },
  account: {
    fontSize: 20,
    fontWeight: 500,
    color: "white",
  },
  profileIcon: ({ chart }) => ({
    color: chart.hover,
    marginLeft: theme.spacing(2),
  }),
  icon: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuIcon: ({ chart }) => ({
    color: chart.hover,
  }),
  profile: ({ chart }) => ({
    color: chart.hover,
  }),
  iconDesktopInner: ({ chart }) => ({
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: 20,
    fontWeight: 500,
    color: chart.color,
    fill: chart.color,
    "&:hover": {
      color: chart.hover,
      fill: chart.hover,
    },
    "& > svg": {
      fontSize: 27,
      color: "inherit",
      marginLeft: theme.spacing(2),
    },
  }),
  iconDesktop: {
    position: "absolute",
    right: 0,
    paddingRight: 40,
  },
  personButton: {
    width: 32,
    padding: 5,
    height: 32,
  },
  logo: ({ chart }) => ({
    color: chart.hover,
    [theme.breakpoints.up("sm")]: {
      width: ({ type }) => (type !== "article" ? 130 : 70),
      height: ({ type }) => (type !== "article" ? 130 : 70),
      transform: "scale(1)",
      transition: "transform .2s linear, top .2s linear",
      zIndex: 4,
      position: ({ type }) => (type !== "article" ? "fixed" : "absolute"),
      left: theme.spacing(5),
      top: ({ displayHeaderBanner }) =>
        displayHeaderBanner ? theme.spacing(6) : theme.spacing(3),
    },
    ["@media(max-width: 1150px)"]: {
      width: 50,
      height: 50,
    },
  }),
  menu: {
    position: "relative",
    height: 80,
    flexBasis: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  isSticky: {
    transformOrigin: "top",
    top: "10px !important",
    width: 130,
    height: 130,
    left: 40,
    transform: "scale(0.5) !important",
    transition: "transform .2s linear, top .2s linear",
    ["@media(max-width: 1150px)"]: {
      left: 0,
    },
  },
  iconButton: {
    backgroundColor: "#F6F6F6",
    borderRadius: 50,
    padding: 5,
  },
  clearIcon: {
    fill: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  listItem: {
    height: "100%",
  },
  innerListItem: {
    display: "flex",
    height: "100%",
  },
  logoIcon: {
    width: 100,
    transition: "400ms",
    "&:hover": {
      cursor: "pointer",
      transform: "rotate(360deg)",
      transition: "1000ms",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  paperDrawer: {
    right: 0,
    height: "100vh",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
  drawerList: {
    height: "100%",
    boxSizing: "border-box",
    overflowY: "auto",
    padding: theme.spacing(4, 3),
    marginBottom: theme.spacing(3),
  },
  drawerListSmall: {
    paddingLeft: theme.spacing(2),
  },
  drawerListItem: {
    borderRadius: 16,
    padding: 16,
  },

  drawerListItemActive: {
    backgroundColor: "#F6F6F6 !important",
  },
  drawerListItemText: {
    "& > span": {
      fontSize: 20,
      fontWeight: 500,
    },
  },
  drawerListSubItemText: {
    "& > span": {
      color: theme.palette.secondary.main,
      fontSize: 20,
      fontWeight: 500,
    },
  },
  drawerListSubItemIcon: {
    color: theme.palette.secondary.main,
  },
  expandMore: {
    transform: "rotate(90deg)",
    color: theme.palette.common.black,
    fontSize: 35,
    transition: "transform .2s linear, top .2s linear",
  },
  language: {
    display: "flex",
    alignItems: "center",
  },
  mapIcon: {
    marginRight: theme.spacing(),
  },
  expandLess: {
    transform: "rotate(270deg) !important",
  },
  bottomActions: {
    backgroundColor: "#F6F6F6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}))

export const REPAIR_STORAGE = "murfy_rebranding_repair_storage"

// Set localStorage data for tunnel v1. Remove once tunnel v2 is stable in prod.
const OLD_REPAIR_STORAGE_ADDRESS = "quickBookingAddress"
const OLD_REPAIR_STORAGE_PRODUCT = "quickBookingProduct"
const setOldAddress = payload => {
  const address = payload.address_components
  localStorage.setItem(OLD_REPAIR_STORAGE_ADDRESS, JSON.stringify(address))
}
const setOldProduct = payload => {
  const product = {
    name: payload.name,
    id: payload._id,
    image: payload.image,
    slug: payload.slug,
  }
  localStorage.setItem(OLD_REPAIR_STORAGE_PRODUCT, JSON.stringify(product))
}
const cleanOldStorage = () => {
  localStorage.removeItem(OLD_REPAIR_STORAGE_ADDRESS)
  localStorage.removeItem(OLD_REPAIR_STORAGE_PRODUCT)
}

export const repairStorage = {
  setAddress: function(address) {
    setOldAddress(address)
    const postalCode = address.address_components
      ? address.address_components.find(component =>
          component.types.includes("postal_code")
        )?.long_name
      : null
    const repairStorage = JSON.parse(this.get())
    repairStorage["address"] = address
    repairStorage["postalCode"] = postalCode
    sessionStorage.setItem(REPAIR_STORAGE, JSON.stringify(repairStorage))
  },
  setProduct: function(product) {
    setOldProduct(product)
    const repairStorage = JSON.parse(this.get())
    repairStorage["product"] = product._id
    repairStorage["productName"] = product.name
    sessionStorage.setItem(REPAIR_STORAGE, JSON.stringify(repairStorage))
  },
  get: function() {
    const storage = sessionStorage.getItem(REPAIR_STORAGE)
    if (!storage) {
      return "{}"
    }
    return storage
  },
  remove: function() {
    cleanOldStorage()
    sessionStorage.removeItem(REPAIR_STORAGE)
  },
}
